import React, { useRef, useEffect, useState } from "react";
import VideoPlayer from "@/components/video-player/video-player";
import useStream from "@/hooks/use-stream";
import "./show-talk-many-streams-masterclass.scss";
import { useEventRoomState } from "@/containers/root-container";
import { eventRoomStore } from "@/stores/event";
import ShowTalkBottomOptions from "../show-talk-bottom-options/show-talk-bottom-options";
import InviteeOptions from "../show-talk-bottom-options/invitee-options/invitee-options";
import HostOptions from "../show-talk-bottom-options/host-options/host-options";

interface ShowTalkStreamsMultiMasterClassProps {
	liveStreams?: any;
	GuideText?: any;
	participants?: boolean;
	handleSwtichStream?: any;
	handleChatClick?: any;
	handleShowPopUp?: any;
	setShowSideStream?: any;
	toggleFullScreenConfirmation?: any;
	handelParticipantsBtn?: any;
	AcceptHandler?: any;
}

const ShowTalkStreamsMultiMasterClass: React.FC<
	ShowTalkStreamsMultiMasterClassProps
> = ({
	liveStreams = {},
	GuideText,
	participants,
	handleSwtichStream,
	handleChatClick,
	handleShowPopUp,
	setShowSideStream,
	toggleFullScreenConfirmation,
	handelParticipantsBtn,
	AcceptHandler,
}) => {
	const [streams, setStreams] = useState<any>({
		mainStreams: [],
		injectedStreams: [],
		// previousStreams: -1,
	});
	const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
	const eventRoomState = useEventRoomState();
	const streamsToPlayOnClick = useRef<any[]>([]);
	const { guideStream } = useStream(eventRoomStore);
	// add streams for which audio autoplay failed
	const handleFailedStreams = (stream: any) => {
		streamsToPlayOnClick.current.push(stream);
	};

	useEffect(() => {}, [liveStreams]);

	useEffect(() => {
		setStreams(liveStreams);
	}, [liveStreams]);

	useEffect(() => {
		eventRoomStore.highlightStreamSpeaking();
	}, []);

	useEffect(() => {
		const handleOnlineStatus = () => setIsOnline(navigator.onLine);
		const handleOfflineStatus = () => setIsOnline(false);

		window.addEventListener("online", handleOnlineStatus);
		window.addEventListener("offline", handleOfflineStatus);

		return () => {
			window.removeEventListener("online", handleOnlineStatus);
			window.removeEventListener("offline", handleOfflineStatus);
		};
	}, []);

	const handlePauseOnNetworkLoss = () => {
		if (!isOnline) {
			// Pause all video players when offline
			document
				.querySelectorAll("video")
				.forEach((videoElement: HTMLVideoElement) => {
					videoElement.pause();
				});
		}
	};

	useEffect(() => {
		handlePauseOnNetworkLoss();
	}, [isOnline]);

	// console.log("all-streams ?????: ", streams);

	return (
		<>
			<div
				className={
					participants ? `large-class large-class-tab` : `large-class `
				}
				style={{
					width: participants ? "75%" : "100%",
				}}
			>
				{/* Main view - guide or Screen Share  */}
				{!eventRoomState.gridView && (
					<div className="main-stream-section">
						{/* show guide stream as main if present */}
						{streams.mainStreams[0] !== undefined && (
							<div className="main-stream">
								<VideoPlayer
									role={streams.mainStreams[0].userRole}
									streamID={streams.mainStreams[0].streamID}
									stream={streams.mainStreams[0].stream}
									domId={`${streams.mainStreams[0].streamID}`}
									id={`${streams.mainStreams[0].streamID}`}
									video={
										typeof streams.mainStreams[0].stream.videoTrack ==
											"undefined" ||
										streams.mainStreams[0].stream.videoTrack == null
											? false
											: streams.mainStreams[0].video
									}
									videoTurnedOn={streams.mainStreams[0].videoTurnedOn}
									audio={
										typeof streams.mainStreams[0].stream.audioTrack ==
											"undefined" ||
										streams.mainStreams[0].stream.audioTrack == null
											? false
											: streams.mainStreams[0].audio
									}
									local={streams.mainStreams[0].local}
									autoplay={true}
									onAutoPlayFailed={handleFailedStreams}
									mainSt={streams.mainStreams[0]}
									name={streams.mainStreams[0].name}
									handleSwitch={handleSwtichStream}
									liveStreams={liveStreams}
								/>
							</div>
						)}
						{!guideStream &&
							streams.mainStreams.length === 0 &&
							eventRoomState.me.info.role != "guide" && (
								<div className="main-stream no-video">
									<h4>
										{eventRoomStore._state.me.info.role == "host"
											? GuideText.host
											: GuideText.residence}
									</h4>
								</div>
							)}

						{eventRoomState.isLive &&
							eventRoomState.rtc.localStream &&
							eventRoomState.me.info.role !== "invitee" && (
								<ShowTalkBottomOptions>
									<HostOptions
										stream={eventRoomState.rtc.localStream.stream}
										audio={eventRoomState.rtc.localStream.audio || false}
										video={
											eventRoomState.rtc.localStream.videoTurnedOn || false
										}
										fullScreenElemId="preshow-talks"
										handleChat={handleChatClick}
										handlePopUp={handleShowPopUp}
										handlesideStream={setShowSideStream}
										toggleFullScreenConfirmation={toggleFullScreenConfirmation}
										handelParticipantsBtn={handelParticipantsBtn}
										handleSwtichStream={handleSwtichStream}
										AcceptHandler={AcceptHandler}
									/>
								</ShowTalkBottomOptions>
							)}
						{eventRoomState.me.info.role === "invitee" && (
							<ShowTalkBottomOptions>
								<InviteeOptions
									fullScreenElemId="preshow-talks"
									handlePopUp={handleShowPopUp}
									handlesideStream={setShowSideStream}
									toggleFullScreenConfirmation={toggleFullScreenConfirmation}
								/>
							</ShowTalkBottomOptions>
						)}
					</div>
				)}
				{/* Side stream - residences */}
				{streams.injectedStreams.length === 0 &&
				eventRoomState.me.info.role === "guide" ? (
					<div id="side-way"></div>
				) : (
					<>
						{eventRoomState.me.role !== 0 &&
							streams.injectedStreams.length > 0 && (
								<div className={`side-stream-section ${!eventRoomStore._state.showAdminVideoPlayer && eventRoomState.me.info.is_multiconnection ? 'admin-view-block' : ''}`} id="side-way">
									{streams.injectedStreams.length > 0 &&
										streams.injectedStreams.map(
											(filteredStream: any, index: number) => {
												const remoteStream = eventRoomState.rtc.remoteStreams.get(`${filteredStream.streamID}`);
												return (
													<div
														style={{
															width: eventRoomState.me.info.is_multiconnection
																? "47%"
																: "100%",
															// width: participants ? "100%" : "100%",
															// width: participants ? "47%" : "47%",
															// display: remoteStream?.userRole =='admin' && !eventRoomStore._state.showAdminVideoPlayer ? 'none' : 'block'
														}}
														className={`side-stream ${
															filteredStream.streamID
														} ${
															filteredStream.streamID ==
																eventRoomState.me.info.host.id && "host-stream"
														} ${!filteredStream.videoTurnedOn && "no-camera"} ${
															!eventRoomState.me.info.is_multiconnection &&
															"tech-visit"
														} ${remoteStream?.userRole =='admin' && !eventRoomStore._state.showAdminVideoPlayer && 'admin-view-data'}`}
														data-step={filteredStream.videoTrack}
														key={filteredStream.streamID}
														data-id={filteredStream.streamID}
													>
														<VideoPlayer
															role={filteredStream?.userRole}
															streamID={filteredStream.streamID}
															stream={filteredStream.stream}
															domId={`${filteredStream.streamID}`}
															id={`${filteredStream.streamID}`}
															video={
																typeof filteredStream.stream.videoTrack ==
																	"undefined" ||
																filteredStream.stream.videoTrack == null
																	? false
																	: filteredStream.video
															}
															videoTurnedOn={filteredStream.videoTurnedOn}
															audio={
																typeof filteredStream.stream.audioTrack ==
																	"undefined" ||
																filteredStream.stream.audioTrack == null
																	? false
																	: filteredStream.audio
															}
															local={filteredStream.local}
															autoplay={true}
															onAutoPlayFailed={handleFailedStreams}
															mainSt={streams.mainStreams[0]}
															name={filteredStream.name}
															index={index}
															handleSwitch={handleSwtichStream}
															liveStreams={liveStreams}
														/>
													</div>
												);
											}
										)}
								</div>
							)}
					</>
				)}
			</div>
		</>
	);
};
export default ShowTalkStreamsMultiMasterClass;
