import React, { useRef, useEffect, useState } from "react";
import "./video-player.scss";
import { useEventRoomState } from "@/containers/root-container";
import useStream from "@/hooks/use-stream";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import RemoteAudioControl from "@/components/remote-audio-control/remote-audio-control";
import RemoteVideoControl from "@/components/remote-video-control/remote-video-control";
import pinIcon from "@/assets/pin-icon.png";
import unPinIcon from "@/assets/unpin-icon.png";
import { eventRoomStore } from "@/stores/event";

interface VideoPlayerProps {
	domId?: string;
	id?: string;
	streamID: number;
	preview?: boolean;
	stream?: any;
	role?: string;
	audio: boolean;
	video: boolean;
	videoTurnedOn: boolean;
	className?: string;
	showProfile?: boolean;
	local?: boolean;
	handleClick?: (type: string, streamID: number, uid: string) => Promise<any>;
	close?: boolean;
	handleClose?: (uid: string, streamID: number) => void;
	autoplay?: boolean;
	onAutoPlayFailed?: (stream: any) => void;
	mainStream?: boolean;
	name: any;
	mainSt?: any;
	index?: any;
	handleSwitch?: (streamID: number) => void;
	liveStreams?: any;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
	role,
	stream,
	domId,
	streamID,
	audio,
	id,
	video,
	videoTurnedOn,
	local,
	name,
	handleSwitch,
	liveStreams = {},
}) => {
	const eventRoomState = useEventRoomState();
	const lockPlay = useRef<boolean>(false);
	const lockPlayAudio = useRef<boolean>(false);
	const { guideStream } = useStream(eventRoomStore);
	const videoFitPosition = useRef<string>("");
	useEffect(() => {
		const playStream = async () => {
			// Clear the previous details inside the main-video container
			const divElement = document.getElementById(`${domId}`);
			if (divElement) {
				divElement.innerHTML = "";
			}

			lockPlay.current = true;
			let isGuideStream = false;
			if (eventRoomState.me.info.role == "guide" && local) {
				isGuideStream = true;
			} else if (eventRoomState.me.info.role != "guide") {
				if (guideStream && guideStream?.streamID == streamID) {
					isGuideStream = true;
				}
			}

			const fit =
				isGuideStream && videoFitPosition.current
					? videoFitPosition.current
					: isGuideStream && !videoFitPosition.current
						? "contain"
						: "cover";

			if (
				typeof stream.videoTrack != "undefined" &&
				stream.videoTrack != null &&
				video &&
				videoTurnedOn
			) {
				if (local) {
					await stream.videoTrack.setEnabled(true); //Enable camera access when a user mutes their local video
				}
				stream.videoTrack.play(`${streamID}`, { fit: fit }, (err: any) => {
					lockPlay.current = false;
					console.warn(
						"[video-player] ",
						JSON.stringify(err),
						id,
						stream.videoTrack.isPaused(),
						stream.videoTrack.isPlaying(),
						" isLocal: ",
						local
					);
				});

				if (isGuideStream) {
					const divElement = document.getElementById(`${streamID}`);
					const videoElement = divElement?.querySelector("video");

					videoElement?.addEventListener("resize", (e: any) => {
						const height = e.target?.videoHeight;
						const width = e.target?.videoWidth;
						if (height && width) {
							if (width > height) {
								videoFitPosition.current = "cover";
								videoElement.style.objectFit =
									videoFitPosition.current || "cover";
							} else {
								videoFitPosition.current = "contain";
								videoElement.style.objectFit =
									videoFitPosition.current || "contain";
							}
						}
					});
				}
			}
		};
		//if (!streamID || (stream.videoTrack==null && stream.audioTrack==null) || (stream.videoTrack!=null && stream.videoTrack.isPlaying) || (stream.audioTrack!=null && stream.audioTrack.isPlaying)) return;
		if (!streamID) return;
		if (video && videoTurnedOn) {
			playStream();
		} else {
			stopStream();
		}
		if (video && videoTurnedOn && stream?.videoTrack && !stream?.videoTrack?.isPlaying) {
			playStream();
		}
		return () => {
			if (stream.videoTrack && stream.videoTrack.isPlaying && stream) {
				try {
					if (local) {
						stream.videoTrack.setEnabled(false); //Disable camera access when a user mutes their local video
					}
					stream.videoTrack.stop();
					lockPlay.current = false;
				} catch (e) {
					console.warn("Stop streaming Error", e);
				}
			}
		};
	}, [streamID, video, videoTurnedOn]);

	useEffect(() => {
		if (!streamID) return;
		playStreamAudio();

		return () => {
			if (stream.audioTrack && stream.audioTrack.isPlaying && stream) {
				try {
					stream.audioTrack.stop();
					lockPlayAudio.current = false;
				} catch (e) {
					console.warn("Stop streaming Error", e);
				}
			}
		};
	}, [streamID, audio]);

	const playStreamAudio = () => {
		lockPlayAudio.current = true;
		if (
			typeof stream.audioTrack != "undefined" &&
			stream.audioTrack != null &&
			audio &&
			eventRoomState.me.uid !== streamID
		) {
			if (!local && sessionStorage.getItem("speakerId")) {
				stream.audioTrack.setPlaybackDevice(
					sessionStorage.getItem("speakerId")
				);
			}
			stream.audioTrack.play();
			lockPlayAudio.current = true;
		}
	};

	const stopStream = async () => {
		// if (stream.videoTrack && stream.videoTrack.isPlaying) {
		try {
			stream?.videoTrack?.stop();
			lockPlay.current = false;
			const divElement = document.getElementById(`${streamID}`);
			const videoElement = divElement?.querySelector("video");
			videoElement?.remove();
		} catch (e) {
			console.warn("Stop streaming Error", e);
		}
		// }
	};

	const [screenshot, setScreenshot] = useState<any>(null);

	useEffect(() => {
		const takeScreenshot = async () => {
			if (
				video &&
				videoTurnedOn &&
				stream?.videoTrack &&
				stream?.videoTrack?.isPlaying
			) {
				const imageData = await stream.videoTrack.getCurrentFrameData();
				if (imageData) {
					const canvas = document.createElement("canvas");
					canvas.width = imageData.width;
					canvas.height = imageData.height;
					const context = canvas.getContext("2d");
					const imageDataObj = new ImageData(
						new Uint8ClampedArray(imageData.data),
						imageData.width,
						imageData.height
					);
					context?.putImageData(imageDataObj, 0, 0);
					const url = canvas.toDataURL();
					setScreenshot(url);
				}
			}
		};

		if (local || !streamID || !stream?.videoTrack?.isPlaying) return;

		// Set interval to take screenshot every 1 second
		const intervalId = setInterval(() => {
			takeScreenshot();
		}, 1000);

		// Clear the interval on component unmount or if dependencies change
		return () => clearInterval(intervalId);

		takeScreenshot();
	}, [local, streamID, stream?.videoTrack?.isPlaying]);

	return (
		<>
			<div
				id={`${domId}`}
				className={`agora-rtc-video ${local ? "rotateY180deg" : ""} ${eventRoomState.gridView ? "gridview" : ""
					}`}
			></div>
			{!local && screenshot !== null && videoTurnedOn && !video && (
				<div
					id="screenshot-video"
					style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
				>
					<img
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
						src={screenshot}
						alt="screenshot"
					/>
				</div>
			)}
			<div className="stream-details">
				<div className="streamUserName" title={name}>
					{name}
				</div>
			</div>
			{/* {!videoTurnedOn && stream?.videoTrack && (
				<div className="">
					<VideocamOffIcon className="videodisableicon" />
				</div>
			)}
			{!video && videoTurnedOn && local && (
				<div className="">
					<VideocamOffIcon className="videodisableicon" />
				</div>
			)}
			{!video && !videoTurnedOn && (
				<div className="">
					<VideocamOffIcon className="videodisableicon" />
				</div>
			)} */}
			{(!video || !videoTurnedOn || (local && !videoTurnedOn) && (!videoTurnedOn && stream?.videoTrack)) && (
				<div className="">
					<VideocamOffIcon className="videodisableicon" />
				</div>
			)}

			{(eventRoomState.me.info.role === "host" ||
				eventRoomState.me.info.role === "residence") &&
				streamID !== liveStreams.mainStreams[0]?.streamID && (
					<div
						className="stream-controls"
						style={{
							left: "50%",
							top: "50%",
							right: "auto",
							backgroundColor: "#FFFFFF",
							borderRadius: 100,
							width: 32,
							height: 32,
							transform: "translate(-50%, -50%)",
						}}
					>
						<button
							style={{
								backgroundColor: "transparent",
								border: 0,
								outline: "none",
								padding: 0,
								cursor: "pointer",
								width: 32,
								height: 32,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={() => {
								handleSwitch && handleSwitch(streamID);
							}}
						>
							<img src={pinIcon} alt="" style={{ width: "80%" }} />
						</button>
					</div>
				)}

			{(eventRoomState.me.info.role == "guide" ||
				eventRoomState.me.info.role == "host") &&
				!local && (
					<div className="stream-controls">
						<RemoteAudioControl streamID={streamID} audio={audio} />
					</div>
				)}
		</>
	);
};

export default React.memo(VideoPlayer);
